import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"

import style from "./styles/layout.module.scss"

import Header from "./header"

const Layout = ({ children, hideMenu }) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
    console.log(hideMenu)
    return (
        <>
          <Header siteTitle={data.site.siteMetadata.title} hideMenu={hideMenu} />
          <main>{children}</main>
          <footer className={style.siteFooter}>
            2020<br/>Real Estate Services Group, Northwest<br/><a href="#top">Back to top</a>
            {hideMenu? <> | <Link to="/">Back to home</Link></> : false}
          </footer>
        </>
    )
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
